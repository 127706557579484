// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Hier eigenes CSS einfügen

html, body {
    color: $font-color;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.15;
}

h1 {
    color: $green;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: 991px) {
        font-size: 2.1875rem;
    }

}
h2 {
    font-size: 2.1875rem;
    margin: 20px 0px;
}

p, .text-list {
    font-size: 1.25rem;
    line-height: 1.32;
}
.text-list ul {
    margin-left: 21px;
    list-style-position: outside;
    li {
        list-style-type: "–";
        padding-left: 8px
    }
}

label {
    position: relative;
    width: max-content;
}

a, .link {
    color: $green;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: #687a00;
        text-decoration: underline;
    }
}

input[type=text], input[type=number], input[type=password], input[type=email], select, .form-select {
    padding: 0.375rem 0.75rem;
    border: 1px solid #e8e8e8;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 500;

    &:focus, &:hover {
        border-color: #d3d3d3;
        box-shadow: none;
    }
}

input[type=checkbox]:focus {
    border-color: rgba(0, 0, 0, 0.25);
    box-shadow: none;
}

input[type="checkbox"], input[type="radio"] {
    cursor: pointer;
}

.form-select {
    padding-right: 30px;
}

.container {
    &.projects {
        @media (min-width: 1400px) {
            max-width: 1400px;
        }
    }
}

.auth-wrapper {
    background-color: #F8F8F8;
}

.btn {
    min-width: 150px;
    padding: 0.8rem 2.5rem;
    line-height: 1.3;
    font-size: 1rem;
    border-radius: 0;
    border: 0px;
    color: #fff;
    background-color: $red;
    font-weight: 500;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
        background-color: $reddark;
    }

    &.disabled {
        background-color: $grey !important;
        cursor: default !important;
    }

}

.btn-group {

    max-width: 450px;
    min-width: 350px;

    .btn {
        padding: 0.7rem 1.84rem;
        line-height: 1.3;
        font-size: 1rem;
        border-radius: 0px;
        background-color: #fff;
        border: 1px solid $bordercolor;
        border-radius: 0px;
        color: $font-color;
        padding: 0.75rem 1.25rem;

        &:hover {
            background-color: $greylight;
        }
    }
    .btn-check:checked + .btn {
        background-color: $red;
        border: 1px solid $red;

        &:hover {
            background-color: $reddark;
            border: 1px solid $reddark;
        }
    }
    .btn-check + .btn:hover {
        color: $font-color;
        border: 1px solid $bordercolor;
    }
    .btn-check:checked + .btn {
        color: #fff;
        border: 1px solid $red;
    }

    .disabled {
        cursor: default !important;
    }

    &.btn-group-mobile {
        .btn {
            min-width: 100px;
        }
    }

}
.hider {
    position: absolute;
    top: -2px;
    left: 0px;
    width: 100%;
    height: 102%;
    background-color: #fff;
    opacity: 0.5;
}

main {
    // @media screen and (max-width: map-get($container-max-widths, "lg")) {
    @media screen and (max-width: 991px) {
        max-width: 900px !important;
    }
}

/**
    Header
*/
header {
    .brand {
        padding: 0 20px;

        #logo-wrapper {
            padding: 10px 0px;
        }
        #logo {
            height: 140px;
            margin-top: .0875rem; // Macht wenig Sinn, ist bei ABS aber so
            margin-bottom: .0875rem; // Macht wenig Sinn, ist bei ABS aber so

            @media (max-width: 767px) {
                height: 100px;
            }
            @media (max-width: 550px) {
                height: 70px;
            }

        }
        .brand-claim {
            padding-top: 32px;
            padding-bottom: 32px;
            font-size: 1.5625rem;
            font-weight: 600;
            @media (max-width: 767px) {
                height: 100px;
                font-size: 1.25rem;
            }
        }
    }
}

.navbar {
    height: 58px;
    font-size: 1.25rem;
    background-color: $grey;
    color: #fff;
    padding: 0px 10px;

    .nav-link {
        padding: 0.5rem 1rem;
        &.active {
            color: $green;
        }
        &:hover {
            color: $green;
        }
    }
}

/**
    Content / Body
*/
main {
    min-height: 650px;
    padding-bottom: 200px;

}
#content-left {
    padding-right: 40px;

    @media screen and (max-width: 991px) {
        padding-left: 10px;
        padding-right: 10px;
    }

}
.page-start {
    margin-top: 60px;
    margin-bottom: 90px;

    @media screen and (max-width: 991px) {
        margin-bottom: 40px;
    }

}

.login {
    padding-top: 60px;
    padding-bottom: 100px;

    .inner {
        margin-top: 80px;
    }

    .btn-login {
        background-color: #aac600;

        &:focus, &:hover {
            background-color: #94ad00!important;
            color: white!important;
        }
    }
}

#tabs-nav {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.32;
    border-bottom: 5px solid $greymiddle;
    padding-bottom: 1px;

    @media screen and (max-width: 1200px) {
        font-size: 1.1rem;
    }

    @media screen and (max-width: 880px) {
        font-size: 0.9rem;
    }

    @media screen and (max-width: 767px) {
        font-size: 1.32rem;
    }

    .nav-item {
        margin-right: 10px;

        @media screen and (max-width: 1200px) {
            margin-right: 5px;
        }

    }
    .nav-tabs {
        border: 0px;
    }
    .nav-link {
        border-radius: 0px;
        color: $font-color;
        background-color: $greylight;
        border: 0px;

        @media screen and (max-width: 1200px) {
            padding: 8px 10px;
        }
        @media screen and (max-width: 767px) {
            padding: 8px 15px !important;
        }
    }
    .nav-link.active {
        background-color: $greymiddle;
        border-color: $greymiddle;
        border: 0px;
    }
    .nav-link.disabled {
        background-color: #F3F3F3;
        pointer-events: none !important; // Disable click, dont follow link
    }
}

/**
    Betriebsenergie
*/
#bauvorhaben {

    width: 100%;
    max-width: 100%;

    label {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;

        label {
            width: 100%;
        }
    }

}
#building {
    width: 400px;
    height: 400px;
    position: relative;

    @media (max-width: 400px) {
        width: 100%;
        max-width: 350px;
    }

}
.twitter-typeahead {
    display: block !important;
}
.tt-menu {
    width: 100%;
    background-color: #fff;
}
.typehead-sugestion {
    padding: 10px;
    cursor: pointer;
}
#address_listbox {
    width: 100%;
    border: 1px solid #ced4da;
}
.tt-dataset .typehead-sugestion:nth-child(odd) {
    background-color: $greylight;
}
.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: auto !important;
}
#modell-anpassen-text {
    @media (max-width: 694px) {
        margin-bottom: 20px;
    }
}

.points-box {
    margin-top: 3.5rem;
    padding: 20px;
    background-color: $greymiddle;
    max-width: 450px;

    .title {
        font-size: 1.2rem;
    }
    .points {
        padding-left: 25px;
        font-size: 1.4rem;
    }
}

#mikrolage-table {
    td {
        white-space: nowrap;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid $bordercolor;
        border-style: dotted;
    }
    td:first-child {
        max-width: 80%;
        padding-right: 30px;
    }
    td:last-child{
        width:100%;
    }
    @media (max-width: 450px) {
        td:first-child {
            // max-width: 175px;
            white-space: normal;
            line-break: auto;
        }
        td:last-child{
            width: auto;
        }
    }
}

/**
    Standort
*/
.standort-grafik {
    padding-right: 5px;

    div {
        width: 10px;
        height: 10px;
        margin-bottom: -4px;
        background-color:  $greymiddle;
        display: inline-block;
        margin-right: 3px;
    }

    .one.active {
        background-color: rgb(236, 0, 0);
    }
    .two.active {
        background-color: rgb(255, 142, 13);
    }
    .three.active {
        background-color: rgb(245, 211, 19);
    }
    .four.active {
        background-color: rgb(19, 161, 19);
    }
    .five.active {
        background-color: rgb(14, 138, 14);
    }
}


/**
    Footer
*/
footer {
    height: 70px;
    background-color: $green;
}

/**
*/
.intro-text {
    margin-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.3;
}

.block {
    margin-top: 3.5rem;
    @media (max-width: 767px) {
        margin-top: 2rem;
    }

    .inner {
        margin-left: 30px;
    }
}

.custom-radio {
    padding-bottom: 10px;
    display: flex;

    .custom-tooltip {
        @media (max-width: 450px) {
         display: inline;
            position: relative;
            right: auto;
        }
    }

    &:first-of-type {
        padding-top: 7px;
    }

    .custom-control-label {
        margin-left: 15px;
        cursor: pointer;
    }

    &.custom-control-inline {
        margin-left: 30px;
    }

}

.custom-tooltip {
    position: absolute;
    right: -26px;
    bottom: 2.5px;
    cursor: default;

    @media (max-width: 550px) {
        display: inline;
        position: relative;
        right: auto;
    }
}

.tooltipOpacity {
    opacity: 0.9!important;

    &.zersiedlung {
        opacity: 1!important;
        inset: -75px auto auto 15px!important;

        &.last {
            inset: -75px auto auto 170px!important;
        }

        .tooltip-arrow {
            display:none!important;
        }

        .tooltip-inner {
            // background-color: transparent!important;
            padding: 10px;
            background-color: #fff;
            border: 1px solid $bordercolor;
            border-radius: 0px;
            color: $font-color !important;
            text-align: left;

            div {
                width: 300px;
            }

            img {
                padding-top: 5px;
            }
        }
    }
}

.tooltip_value {
    text-align: start;
}

.tooltip-inner {
    max-width: 350px;
}

.form-group {
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    .input-label {
        font-size: 1.1rem;
        line-height: 1.3;
        margin-bottom: 10px;

        @media (max-width: 600px) {
            max-width: 100%;
            white-space: normal;
        }

    }

    .form-check {
        display: flex;
        align-items: center;

        .form-check-input {
            width: 16px;
            min-width: 16px;
            margin-top: 0;
        }

        .form-check-label {
            font-size: 1.1rem;
            margin-left: 20px;
            cursor: pointer;
        }
    }

    .error {
        position: absolute;
        right: -385px;
        bottom: 10px;
        color:#cf1152;
    }
}

#rating {

    .kategorie {
        margin-bottom: 0.5rem;
        font-weight: 600;
        line-height: 1.2;
        font-size: 1.2rem;
    }

    .item {

        position: relative;

        p {
            font-size: 1.1rem;
            line-height: 1.3;
            margin-bottom: 10px;
            text-indent: 40px;
        }

        img {
            position: absolute;
            margin-top: 0.5px;
            margin-left: 10px;
        }
    }

    .rating-label {
        padding: 2px 6px;
        border-radius: 5px;
        font-size: 1rem;
    }
    .green {
        background-color: rgb(225, 255, 225);
    }
    .yellow {
        background-color: rgb(255, 249, 209);
    }
    .red {
        background-color: rgb(255, 226, 226);
    }
}

.legend {

    margin-left: 35px;

    .label {
        width: 40px;
        height: 1px;
        border-bottom: 3px solid rgb(54, 162, 235);

        &.dashed {
            border-bottom: 3px dashed rgba(0, 0, 0, 0.7);
        }
    }

    .text {
        margin-left: 15px;
    }
}

.main-box {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: $greymiddle;
    font-size: 1.2rem;
    font-weight: 600;
}

.zusammenfassung {

    max-width: 550px;

    @media (max-width: 700px) {
        max-width: 100%;
        width: 100%;
    }

    p {
        font-size: 1.1rem;
    }

    .rating-box {

        margin-top: 22px;
        font-size: 1.1rem;

        .top {
            padding: 17px 20px;
            background-color: $greylight;
            font-weight: 600;
        }

        .inner {
            padding-left: 20px;
            padding-right: 20px;
            .item {
                padding-top: 6px;
                display: flex;
                justify-content: space-between;

                &:first-child {
                    padding-top: 8px;
                }
            }
        }
    }

}

#info {

    p {
        font-size: 1.1rem;
    }
    .info-title {
        font-weight: 600;
        font-size: 1.2rem;
    }
}

#kontakt {
    #absendenButton {
        @media (max-width: 450px) {
            float: right;
        }
    }
    .disableProspektberatung {
        background-color: #e9ecef;
        opacity: 1;
    }
}

#projektverwaltung, #benutzerverwaltung {

    .title_row hr {
        width: 100%;
    }

    .projekte .title_row,
    .user .title_row {
        margin-left: 0;
        margin-right: 0;
    }

    .projekte .title_row .clickable {
        cursor: pointer;
        width: fit-content;
        border-bottom: 1px solid transparent;
        opacity: 0.7;
    }

    .projekte .title_row .clickable:hover,
    .projekte .title_row .clickable:focus,
    .projekte .title_row .clickable.clicked {
        color: $font-color;
        border-color: $font-color;
        opacity: 1;
    }

    .projekte .title_row .clickable img {
        width: 13px;
        transform: rotate(-90deg);
        margin-bottom: 4px;
        opacity: 0.5;
    }

    .projekte .title_row .clickable.ascending img
    {
        transform: rotate(90deg);
    }

    .projekte .title_row .clickable:hover img,
    .projekte .title_row .clickable:focus img,
    .projekte .title_row .clicked img,
    .projekte .title_row .clicked img {
        opacity: 0.9;
    }

    #searchCustomer input, #searchAddress input {
        width:100%;
        margin: 0;
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: 97% 45%;
    }

    .customer {
        width: 13%;
    }

    .address {
        width: 20.33%;
    }

    .pageable .row, .searchable .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $greylight;
        margin-left: 0;
        margin-right: 0;
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .btns a, .btns span, .projv-btn {
        &:hover {
            opacity: 0.7;
        }
    }

    .delete-btn, .pen-btn {
        width: 25px;
        min-width: 0;
        padding: 0;
        max-height: 46.5px;
        background-color: transparent;

        svg {
            width: 25px;
            opacity: 0.75;
        }
    }

    .pen-btn {
        height: 22px;
    }

    .pdf-btn {
        display: flex;
        justify-content: center;
        width: 32px;
        min-width: 0;
        padding: 0;
        max-height: 46.5px;
        background-color: transparent;
        margin-right: 8px;

        img {
            width: 32px;
            opacity: 0.8;
        }
    }

    .icon {
        cursor: pointer;
    }

    .btn-secondary {
        width: 100%;
        min-width: 60px;
        padding: 0.8rem 1.8rem;
        text-decoration: none !important;
    }
    .open {
        width: auto;
        padding: 0.8rem 0.5rem;
    }

    .pagination .page-link {
        color: $grey;
        border-color: $bordercolor;
    }

    .pagination .page-link:focus {
        box-shadow: none;
    }

    .pagination .page-link:hover {
        //background-color: $greylight;
    }

    .pagination .page-item.active .page-link {
        color: #fff;
        background-color: $grey;
        border-color: $grey;
    }

    .pagination .sr-only {
        display: none;
    }
}

.user-icon {
    width: auto;
    height: 25px;
    margin-top: 8px;
    margin-right: 6px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.delete-angefangenes {
    text-align: right;

    svg {
        width: 25px;
        opacity: 0.75;
        cursor: pointer;
    }
}

#unfinished-table {

    .row {
        align-items: center;
        height: 55px;
        border-bottom: 1px solid #f2f7fa;
    }
}

.modal-dialog {
    margin: 7% auto;
    max-width:550px;

    .close {
        background-color: transparent;
        border: 0;
        padding: 0;
        opacity: 0.7;
        font-size: 1.5rem;

        span {
            max-height: fit-content;
        }
    }
}

.deleteModal, .modals {

    &.modal {
        height: 1000px;
    }

    .modal-dialog {
        max-width: 500px;
        margin-top: 30px;
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: 767px) {
            max-width: 380px;
        }

        @media (max-width: 575px) {
            max-width: 365px;
            font-size: 0.95rem;
        }

        @media (min-width: 350px) {
            @media (min-height: 290px) {
                margin-top: 60px;
            }

            @media (min-height: 370px) {
                margin-top: 100px;
            }

            @media (min-height: 430px) {
                margin-top: 140px;
            }

            @media (min-height: 550px) {
                margin-top: 200px;
            }
        }

        @media (max-width: 349px) {
            @media (min-height: 360px) {
                margin-top: 60px;
            }

            @media (min-height: 440px) {
                margin-top: 100px;
            }

            @media (min-height: 530px) {
                margin-top: 140px;
            }

            @media (min-height: 640px) {
                margin-top: 200px;
            }
        }

        @media (max-width: 299px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .modal-content {
            border-radius: 0;
            border-color:transparent;
            -webkit-box-shadow: 1px 8px 22px -8px rgba(black,0.9);
            -moz-box-shadow: 1px 8px 22px -8px rgba(black,0.9);
            box-shadow: 1px 8px 22px -8px rgba(black,0.9);

            .modal-header {
                background-color: rgba($greylight,1);
                -webkit-box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                -moz-box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                .modal-title {
                    font-weight: 600;
                }
            }

            .modal-body {
                padding: 20px;

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                    @media (max-width: 349px) {
                        display: block;
                    }
                    div {

                        &:nth-child(2) {
                            @media (min-width: 350px) {
                                padding-left: 15px;
                            }
                        }

                        button {
                            @media (max-width: 575px) {
                                font-size: 0.95rem;
                            }

                            @media (max-width: 349px) {
                                margin-top: 10px;
                                width: 100%;
                            }
                        }
                    }
                }

            }
        }
    }
}

.modellAnpassenModal {
    .modal-dialog {
        width: 90%;
        max-width: 700px;
    }
}

.userModal {
    &.modal {
        height: 1000px;
    }

    .modal-dialog {
        max-width: 550px;
        margin-top: 30px;
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: 767px) {
            max-width: 450px;
        }

        @media (max-width: 575px) {
            max-width: 365px;
            font-size: 0.95rem;
        }

        @media (min-width: 350px) {
            @media (min-height: 290px) {
                margin-top: 60px;
            }

            @media (min-height: 370px) {
                margin-top: 100px;
            }

            @media (min-height: 430px) {
                margin-top: 140px;
            }

            @media (min-height: 550px) {
                margin-top: 200px;
            }
        }

        @media (max-width: 349px) {
            @media (min-height: 360px) {
                margin-top: 60px;
            }

            @media (min-height: 440px) {
                margin-top: 100px;
            }

            @media (min-height: 530px) {
                margin-top: 140px;
            }

            @media (min-height: 640px) {
                margin-top: 200px;
            }
        }

        @media (max-width: 299px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .modal-content {
            border-radius: 0;
            border-color:transparent;
            -webkit-box-shadow: 1px 8px 22px -8px rgba(black,0.9);
            -moz-box-shadow: 1px 8px 22px -8px rgba(black,0.9);
            box-shadow: 1px 8px 22px -8px rgba(black,0.9);

            .modal-header {
                background-color: rgba($greylight,1);
                -webkit-box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                -moz-box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                box-shadow: 0px 7px 10px -8px rgba($greymiddle,1);
                .modal-title {
                    font-weight: 600;
                }
            }

            .modal-body {
                padding: 20px;
                padding-top: 30px;

                @media (max-width: 399px) {
                    padding: 15px;
                    padding-top: 25px;
                }

                input, button, select {
                    @media (max-width: 575px) {
                        font-size: 0.95rem;
                    }
                }
            }
        }
    }

    &.new {
        .modal-dialog {
            @media (min-width: 350px) {
                @media (min-height: 550px) {
                    margin-top: 120px;
                }
            }
        }
    }
}

#userdata {
    td {
        padding: 4px 0px;
        word-break: break-word;
    }
    td:first-child {
        // width: auto;
        width: 50%;
        // white-space: nowrap;
        padding-right: 30px;
        opacity: 0.8;
    }
    td:last-child {
        width: 50%;
    }
}

#abschluss {

    #block2 {
        margin-top: 60px;
        @media (max-width: 991px) {
            margin-top: 40px;
        }
    }
    #block3 {
        margin-top: 75px;
        @media (max-width: 991px) {
            margin-top: 50px;
        }
    }
    #block4 {
        margin-top: 130px;
        @media (max-width: 991px) {
            margin-top: 40px;
        }
    }

}

#bauoekologie {
    @media screen and (max-width: 450px) {
        .inner {
            margin-left: 0px;
        }
    }
}

#radarChart {
    max-width: 500px;
    max-height: 500px;
}

.form-error {
    border: 1px solid #cf1152 !important;
}
.deaktiviert {
    opacity: 0.5;
    cursor: default !important;

    .custom-control-label {
        cursor: default !important;
    }
}

.opacity025 {
    opacity: 0.25;
}
.light {
    opacity: 0.6;
}
.smaller {
    font-size: 60%;
}

#zertifikat_preview {
    width: 100%;
    max-width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-left: 5px; margin-top: 6px;
}
.unselectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.btn-secondary {
    text-decoration: none !important;
}
