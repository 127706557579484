$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1310px
);

// Body
$body-bg: #fff;

// Typography
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:normal;
  font-weight:500;
  src:url('../fonts/avenir-next-lt-medium.woff2');
}
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:italic;
  font-weight:500;
  src:url('../fonts/avenir-next-lt-medium-italic.woff2');
}
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:normal;
  font-weight:600;
  src:url('../fonts/avenir-next-lt-demi.woff2');
}
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:italic;
  font-weight:600;
  src:url('../fonts/avenir-next-lt-demi-italic.woff2');
}
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:normal;
  font-weight:900;
  src:url('../fonts/avenir-next-lt-heavy.woff2');
}
@font-face{
  font-display:swap;
  font-family:Avenir Next LT;
  font-style:italic;
  font-weight:900;
  src:url('../fonts/avenir-next-lt-heavy-italic.woff2');
}


$font-family-sans-serif: Avenir Next LT,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
// $font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$green: #AAC600;
$red: #cf1152;
$reddark: #ac0e44;
$grey: #49585f;
$greymiddle: #cadae2;
$greylight: #f2f7fa;
$bordercolor: #ced4da;
$font-color: #49585f;
